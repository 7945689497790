export const TYPE_SEARCH = "search";
export const TYPE_PRODUCT = "product";
export const TYPES = [TYPE_SEARCH, TYPE_PRODUCT];

/**
 * Front urls
 */
export const HOME_FRONT_URL = "/home";
export const SLIPPAGE_FRONT_URL = "/management/slippage"; //
export const RPQ_REPORT_FRONT_URL = "/management/RpqReport";
export const OVERVIEW_FRONT_URL = "/report/overview"; //
export const SEARCH_CAMPAIGN_FRONT_URL = "/search/campaign";
export const CAMPAIGN_FRONT_URL = "/campaign";
export const SEARCH_FEED_FRONT_URL = "/search/feed";
export const FEED_FRONT_URL = "/feed";
export const SEARCH_REPORT_FRONT_URL = "/search/feed_report"; //
export const SEARCH_ADVERTISER_DATA_CONTROL = "/search/advertiser_data_control"; // 
export const SEARCH_QUERIES_REPORT_FRONT_URL = "/search/queries_report"; //
export const PRODUCT_REPORT_FRONT_URL = "/products/report";
export const PRODUCT_CAMPAIGN_FRONT_URL = "/products/campaign";
export const PRODUCT_OFFER_FRONT_URL = "/products/feed";
export const FEED_BALANCER_GEO_GROUPS_FRONT_URL = "/feedbalancer/geo_groups"; //
export const REDIRECT_GROUPS_URL = "/feedbalancer/redirect_groups"; //
export const ACCOUNT_FRONT_URL = "/accounts";
export const PARTNER_FRONT_URL = "/partner"; //
export const PUBLISHER_FRONT_URL = "/publisher"; //
export const ADVERTISER_FRONT_URL = "/advertiser"; //
export const BALANCE_FRONT_URL = "/balance"; //
export const USER_FRONT_URL = "/user"; //
export const LOGS_FRONT_URL = "/logs"; //

export const SEARCH_BLACKLISTED_QUERIES_URL = "/search/blacklisted_queries";

/**
 * '/temp-place-holder'
 */
