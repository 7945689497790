import { Suspense, lazy, useState } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { useEffect } from "react";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";
// guard
import AuthGuard from "../guards/AuthGuard";

import Cookies from "js-cookie";
import store from "../store";
import jwtDecode from "jwt-decode";
import { setCurrentUser } from "../service/login.service";
import { useSelector } from "react-redux";
import { getCurrentUserRole } from "src/selectors/auth.selector";
import { useNavigate } from "react-router";
import RoleBasedGuard from "src/guards/RoleBasedGuard";
// ----------------------------------------------------------------------
import Http from "../service/httpservice";
import {
  HOME_FRONT_URL,
  SLIPPAGE_FRONT_URL,
  RPQ_REPORT_FRONT_URL,
  OVERVIEW_FRONT_URL,
  SEARCH_CAMPAIGN_FRONT_URL,
  CAMPAIGN_FRONT_URL,
  SEARCH_FEED_FRONT_URL,
  FEED_FRONT_URL,
  SEARCH_REPORT_FRONT_URL,
  SEARCH_QUERIES_REPORT_FRONT_URL,
  PRODUCT_REPORT_FRONT_URL,
  PRODUCT_CAMPAIGN_FRONT_URL,
  PRODUCT_OFFER_FRONT_URL,
  FEED_BALANCER_GEO_GROUPS_FRONT_URL,
  ACCOUNT_FRONT_URL,
  PARTNER_FRONT_URL,
  PUBLISHER_FRONT_URL,
  ADVERTISER_FRONT_URL,
  BALANCE_FRONT_URL,
  USER_FRONT_URL,
  LOGS_FRONT_URL,
  SEARCH_BLACKLISTED_QUERIES_URL,
  REDIRECT_GROUPS_URL,
  SEARCH_ADVERTISER_DATA_CONTROL
} from "../helper/constant/constant";

const cookieToken = Cookies.get("feed-balancer-token");

if (localStorage["feed-balancer-token"] && cookieToken) {
  const parsedToken = JSON.parse(localStorage["feed-balancer-token"]);
  const { token } = parsedToken;
  const { timestamp } = parsedToken;
  const today = new Date();

  const previousDate = new Date(timestamp);
  previousDate.setDate(previousDate.getDate() + 7);

  const fullDateToday = today.getTime();
  const fullPreviousDate = previousDate.getTime();

  if (fullDateToday <= fullPreviousDate) {
    const decoded = jwtDecode(token);
    store.dispatch(setCurrentUser(decoded));
    // store.dispatch(setCurrentUser({j: 'j'}));
    //---\
    //store.dispatch(setCurrentOverview(decoded))
  } else {
    localStorage.removeItem("feed-balancer-token");
    Cookies.remove("feed-balancer-token");
  }
}
const useCurrentRole = () => useSelector(getCurrentUserRole);
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed"
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

function getPathChildrenPerType(type) {
  // returns a list of objects representing the paths for the type: search / products
  const output = [
    {
      path: "campaign",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<Campaign type={type} />} />
    },
    {
      path: "campaign/:campaignId/revenue-share",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<CampaignRateCardList type={type} />} />
    },
    {
      path: "campaign/:campaignId/add-revenue-share",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<AddCampaignRateCard type={type} />} />
    },
    {
      path: "campaign/:campaignId/edit-revenue-share/:id",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<EditCampaignRateCard type={type} />} />
    },
    { path: "feed", element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<Feed type={type} />} /> },

    /* Manage Feed Route*/
    {
      path: "feed/manage-feed/",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<AdvertiserFeedMapList type={type} />} />
    },
    {
      path: "campaign_add/:id",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<Campaign_add type={type} />} />
    },
    {
      path: "campaign_edit/:id",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<Campaign_edit type={type} />} />
    },
    {
      path: "campaign_source/:id",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<Campaign_source type={type} />} />
    },
    {
      path: "campaign/:campaignId/rate-card",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<CampaignRateCardList type={type} />} />
    },
    {
      path: "campaign/:campaignId/add-rate-card",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<AddCampaignRateCard type={type} />} />
    },
    {
      path: "campaign/:campaignId/edit-rate-card/:id",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<EditCampaignRateCard type={type} />} />
    },
    {
      path: "campaign/:campaignId/factor",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<CampaignFactorList type={type} />} />
    },
    {
      path: "campaign/:campaignId/add-factor",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<AddCampaignFactor type={type} />} />
    },
    {
      path: "campaign/:campaignId/edit-factor/:id",
      element: <RoleBasedGuard currentURL={CAMPAIGN_FRONT_URL} children={<EditCampaignFactor type={type} />} />
    },
    {
      path: "feed_add/:partner_id",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<Feed_add type={type} />} />
    },
    {
      path: "feed/:feedId/rate-card",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<FeedRateCardList type={type} />} />
    },
    {
      path: "feed/:feedId/add-rate-card",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<AddFeedRateCard type={type} />} />
    },
    {
      path: "feed/:feedId/edit-rate-card/:id",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<EditFeedRateCard type={type} />} />
    },
    {
      path: "feed/:feedId/factor",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<FeedFactorList type={type} />} />
    },
    {
      path: "feed/:feedId/rate",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<FeedRateList type={type} />} />
    },
    {
      path: "feed/:feedId/add-factor",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<AddFeedFactor type={type} />} />
    },
    {
      path: "feed/:feedId/edit-factor/:id",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<EditFeedFactor type={type} />} />
    },
    {
      path: "feed/:feedId/add-rate",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<AddFeedRate type={type} />} />
    },
    {
      path: "feed/:feedId/edit-rate/:id",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<EditFeedRate type={type} />} />
    },
    {
      path: "feed_edit/:id",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<Feed_add type={type} />} />
    },
    {
      path: "feed_hardcoded/:id",
      element: <RoleBasedGuard currentURL={FEED_FRONT_URL} children={<Feed_hardcoded type={type} />} />
    }
  ];
  if (type === "search") {
    output.push({
      path: "feed_report",
      element: <RoleBasedGuard currentURL={SEARCH_REPORT_FRONT_URL} children={<FeedReport />} />
    });
    output.push({
      path: "advertiser_data_control",
      element: <RoleBasedGuard currentURL={SEARCH_ADVERTISER_DATA_CONTROL} children={<AdvertiserDataControl />} />
    });
    output.push({
      path: "queries_report",
      element: <RoleBasedGuard currentURL={SEARCH_QUERIES_REPORT_FRONT_URL} children={<QueriesReport />} />
    });
    output.push({
      path: "blacklisted_queries",
      element: <RoleBasedGuard currentURL={SEARCH_BLACKLISTED_QUERIES_URL} children={<BlackListedQueries />} />
    });
    output.push({
      path: "blacklisted_queries/add",
      element: <RoleBasedGuard currentURL={SEARCH_BLACKLISTED_QUERIES_URL} children={<BlackListedQueriesAddPage />} />
    });
    output.push({
      path: "blacklisted_queries/edit/:query",
      element: <RoleBasedGuard currentURL={SEARCH_BLACKLISTED_QUERIES_URL} children={<BlackListedQueriesEditPage />} />
    });
  } else {
    output.push({
      path: "report",
      element: <RoleBasedGuard currentURL={PRODUCT_REPORT_FRONT_URL} children={<ProductReport />} />
    });
  }
  return output;
}

function getDefaultNavRoute(currentRole) {
  return <Navigate to="/home" replace />;
}

export default function Router() {
  const currentRole = useCurrentRole();
  let navigate = useNavigate();

  useEffect(() => {
    if (!currentRole) {
      navigate("/login");
    }
  }, [currentRole]);

  return useRoutes([
    {
      path: "/login",
      element: <Login />
    },
    // Dashboard Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: getDefaultNavRoute(currentRole) },
        {
          path: "home",
          element: <RoleBasedGuard currentURL={HOME_FRONT_URL} children={<Homepage />} />
        },
        {
          path: "search",
          children: getPathChildrenPerType("search")
        },
        {
          path: "product",
          children: getPathChildrenPerType("product")
        },
        {
          path: "feedbalancer",
          children: [
            { element: <Navigate to="/feedbalancer/geo_groups" replace /> },
            {
              path: "geo_groups",
              element: <RoleBasedGuard currentURL={FEED_BALANCER_GEO_GROUPS_FRONT_URL} children={<Geo_groups />} />
            },
            {
              path: "geo_add",
              element: <RoleBasedGuard currentURL={FEED_BALANCER_GEO_GROUPS_FRONT_URL} children={<Geo_add />} />
            },
            {
              path: "geo_edit/:id",
              element: <RoleBasedGuard currentURL={FEED_BALANCER_GEO_GROUPS_FRONT_URL} children={<Geo_edit />} />
            },
            {
              path: "redirect_groups",
              element: <RoleBasedGuard currentURL={REDIRECT_GROUPS_URL} children={<RedirectGroups />} />
            },
            {
              path: "redirect_groups/add",
              element: <RoleBasedGuard currentURL={REDIRECT_GROUPS_URL} children={<RedirectGroupsAddEdit />} />
            },
            {
              path: "redirect_groups/edit/:id",
              element: <RoleBasedGuard currentURL={REDIRECT_GROUPS_URL} children={<RedirectGroupsAddEdit />} />
            }
          ]
        },
        {
          path: "accounts",
          children: [
            {
              path: "/accounts",
              element: <RoleBasedGuard currentURL={ACCOUNT_FRONT_URL} children={<Account />} />
            },
            {
              path: "/accounts/edit/:id",
              element: <RoleBasedGuard currentURL={ACCOUNT_FRONT_URL} children={<AccountEdit />} />
            }
          ]
        },
        {
          path: "partner",
          children: [
            {
              path: "/partner",
              element: <RoleBasedGuard currentURL={PARTNER_FRONT_URL} children={<Partner />} />
            },
            {
              path: "/partner/edit/:id",
              element: <RoleBasedGuard currentURL={PARTNER_FRONT_URL} children={<Partner_AddEdit />} />
            },
            {
              path: "/partner/view/:id",
              element: <RoleBasedGuard currentURL={PARTNER_FRONT_URL} children={<Partner_AddEdit />} />
            },
            {
              path: "/partner/add",
              element: <RoleBasedGuard currentURL={PARTNER_FRONT_URL} children={<Partner_AddEdit />} />
            }
          ]
        },
        {
          path: "balance",
          children: [
            {
              path: "/balance",
              element: <RoleBasedGuard currentURL={BALANCE_FRONT_URL} children={<Balance />} />
            },
            {
              path: "/balance/add",
              element: <RoleBasedGuard currentURL={BALANCE_FRONT_URL} children={<Balance_Add />} />
            }
          ]
        },
        {
          path: "logs",
          children: [
            {
              path: "/logs",
              element: <RoleBasedGuard currentURL={LOGS_FRONT_URL} children={<LogsReport />} />
            },
            {
              path: "/logs/:name/:id",
              element: <RoleBasedGuard currentURL={LOGS_FRONT_URL} children={<LogsReport />} />
            }
          ]
        },
        {
          path: "affilation",
          children: [
            { element: <Navigate to="/affilation/advertiser-campaign" replace /> },
            {
              path: "advertiser-campaign",
              element: <RoleBasedGuard currentURL={ADVERTISER_FRONT_URL} children={<AdvertiserCampaign />} />
            },
            {
              path: "advertiser-add-campaign/:id",
              element: <RoleBasedGuard currentURL={ADVERTISER_FRONT_URL} children={<AdvertiserAddCampaign />} />
            },
            {
              path: "advertiser-edit-campaign/:id",
              element: <RoleBasedGuard currentURL={ADVERTISER_FRONT_URL} children={<AdvertiserEditCampaign />} />
            },

            {
              path: "advertiser-campaign/:campaignId/rate-card",
              element: <RoleBasedGuard currentURL={ADVERTISER_FRONT_URL} children={<AdvertiserRateCardList />} />
            },
            {
              path: "advertiser-campaign/:campaignId/add-rate-card",
              element: <RoleBasedGuard currentURL={ADVERTISER_FRONT_URL} children={<AddAdvertiserRateCard />} />
            },
            {
              path: "advertiser-campaign/:campaignId/edit-rate-card/:id",
              element: <RoleBasedGuard currentURL={ADVERTISER_FRONT_URL} children={<EditAdvertiserRateCard />} />
            },

            {
              path: "advertiser-campaign/:campaignId/factor",
              element: <RoleBasedGuard currentURL={ADVERTISER_FRONT_URL} children={<AdvertiserFactorList />} />
            },
            {
              path: "advertiser-campaign/:campaignId/add-factor",
              element: <RoleBasedGuard currentURL={ADVERTISER_FRONT_URL} children={<AddAdvertiserFactor />} />
            },
            {
              path: "advertiser-campaign/:campaignId/edit-factor/:id",
              element: <RoleBasedGuard currentURL={ADVERTISER_FRONT_URL} children={<EditAdvertiserFactor />} />
            },

            {
              path: "publisher-campaign",
              element: <RoleBasedGuard currentURL={PUBLISHER_FRONT_URL} children={<PublisherCampaign />} />
            },
            {
              path: "publisher-add-campaign/:id",
              element: <RoleBasedGuard currentURL={PUBLISHER_FRONT_URL} children={<PublisherAddCampaign />} />
            },
            {
              path: "publisher-edit-campaign/:id",
              element: <RoleBasedGuard currentURL={PUBLISHER_FRONT_URL} children={<PublisherEditCampaign />} />
            },

            {
              path: "publisher-campaign/:campaignId/rate-card",
              element: <RoleBasedGuard currentURL={PUBLISHER_FRONT_URL} children={<PublisherRateCardList />} />
            },
            {
              path: "publisher-campaign/:campaignId/add-rate-card",
              element: <RoleBasedGuard currentURL={PUBLISHER_FRONT_URL} children={<AddPublisherRateCard />} />
            },
            {
              path: "publisher-campaign/:campaignId/edit-rate-card/:id",
              element: <RoleBasedGuard currentURL={PUBLISHER_FRONT_URL} children={<EditPublisherRateCard />} />
            },

            {
              path: "publisher-campaign/:campaignId/factor",
              element: <RoleBasedGuard currentURL={PUBLISHER_FRONT_URL} children={<PublisherFactorList />} />
            },
            {
              path: "publisher-campaign/:campaignId/add-factor",
              element: <RoleBasedGuard currentURL={PUBLISHER_FRONT_URL} children={<AddPublisherFactor />} />
            },
            {
              path: "publisher-campaign/:campaignId/edit-factor/:id",
              element: <RoleBasedGuard currentURL={PUBLISHER_FRONT_URL} children={<EditPublisherFactor />} />
            }
          ]
        },
        {
          path: "user",
          children: [
            { path: "/user", element: <RoleBasedGuard currentURL={USER_FRONT_URL} children={<User />} /> },
            {
              path: "/user/edit/:id",
              element: <RoleBasedGuard currentURL={USER_FRONT_URL} children={<User_edit />} />
            },
            {
              path: "/user/add",
              element: <RoleBasedGuard currentURL={USER_FRONT_URL} children={<User_add />} />
            }
          ]
        },
        {
          path: "report",
          children: [
            { element: <Navigate to="/report/overview" replace /> },
            {
              path: "overview",
              element: <RoleBasedGuard currentURL={OVERVIEW_FRONT_URL} children={<Overview />} />
            }
          ]
        },
        {
          path: "management",
          children: [
            { element: <Navigate to="/management/slippage" replace /> },
            {
              path: "slippage",
              element: <RoleBasedGuard currentURL={SLIPPAGE_FRONT_URL} children={<Slippage />} />
            },
            // {
            //   path: "reports",
            //   element: <RoleBasedGuard currentURL={'/temp-place-holder'} children={<MainReports />} /> // *  not sure if this is necessary (part of the vegan report).
            // },
            // {
            //   path: "revenues-edit",
            //   element: <RoleBasedGuard currentURL={'/temp-place-holder'} children={<RevenuesEdit />} /> // *  not sure if this is necessary (part of the vegan report).
            // },
            {
              path: "RpqReport",
              element: <RoleBasedGuard currentURL={RPQ_REPORT_FRONT_URL} children={<RpqReport />} />
            }
          ]
        }
      ]
    },
    { path: "*", element: <Navigate to="/404" replace /> },
    { path: "404", element: <NotFound /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const Homepage = Loadable(lazy(() => import("../pages/Homepage")));
const Login = Loadable(lazy(() => import("../pages/Login")));
const FeedReport = Loadable(lazy(() => import("../pages/Management/FeedReport")));
const AdvertiserDataControl = Loadable(lazy(() => import("../pages/AdvertiserDataControl")));
const Overview = Loadable(lazy(() => import("../pages/Management/Overview")));
const MainReports = Loadable(lazy(() => import("../pages/Management/kenys")));
const Slippage = Loadable(lazy(() => import("../pages/Management/Slippage")));
const RevenuesEdit = Loadable(lazy(() => import("../pages/Management/Overview/RevenuesEdit")));
const RpqReport = Loadable(lazy(() => import("../pages/Management/RpqReport")));

const Campaign = Loadable(lazy(() => import("../pages/Campaign")));
const Campaign_add = Loadable(lazy(() => import("../pages/Campaign/Add")));
const Campaign_edit = Loadable(lazy(() => import("../pages/Campaign/Edit")));
const Campaign_source = Loadable(lazy(() => import("../pages/Campaign/SourceControl/SourceControl")));

const CampaignRateCardList = Loadable(lazy(() => import("../pages/Campaign/Campaign-rate-card")));
const AddCampaignRateCard = Loadable(lazy(() => import("../pages/Campaign/Campaign-rate-card/AddRateCard")));
const EditCampaignRateCard = Loadable(lazy(() => import("../pages/Campaign/Campaign-rate-card/EditRateCard")));

const CampaignFactorList = Loadable(lazy(() => import("../pages/Campaign/Campaign-factor")));
const AddCampaignFactor = Loadable(lazy(() => import("../pages/Campaign/Campaign-factor/AddFactor")));
const EditCampaignFactor = Loadable(lazy(() => import("../pages/Campaign/Campaign-factor/EditFactor")));

const Geo_groups = Loadable(lazy(() => import("../pages/Geogroups")));
const Geo_add = Loadable(lazy(() => import("../pages/Geogroups/Add")));
const Geo_edit = Loadable(lazy(() => import("../pages/Geogroups/Edit")));
const Feed = Loadable(lazy(() => import("../pages/Feed")));

const Feed_add = Loadable(lazy(() => import("../pages/Feed/Add")));
const Feed_hardcoded = Loadable(lazy(() => import("../pages/Feed/feed-hardcoded-camps")));

const FeedRateCardList = Loadable(lazy(() => import("../pages/Feed/Feed-rate-card")));
const AddFeedRateCard = Loadable(lazy(() => import("../pages/Feed/Feed-rate-card/AddRateCard")));
const EditFeedRateCard = Loadable(lazy(() => import("../pages/Feed/Feed-rate-card/EditRateCard")));

const FeedFactorList = Loadable(lazy(() => import("../pages/Feed/Feed-factor")));
const FeedRateList = Loadable(lazy(() => import("../pages/Feed/Feed-rate")));

/* Manage Feed Id List */
const AdvertiserFeedMapList = Loadable(lazy(() => import("../pages/Feed/Advertise-Feed-Maps")));

const AddFeedFactor = Loadable(lazy(() => import("../pages/Feed/Feed-factor/AddFactor")));
const EditFeedFactor = Loadable(lazy(() => import("../pages/Feed/Feed-factor/EditFactor")));
const AddFeedRate = Loadable(lazy(() => import("../pages/Feed/Feed-rate/AddRate")));
const EditFeedRate = Loadable(lazy(() => import("../pages/Feed/Feed-rate/EditRate")));

const ProductReport = Loadable(lazy(() => import("../pages/ProductsReport")));
const Account = Loadable(lazy(() => import("../pages/Account/Index")));
const AccountEdit = Loadable(lazy(() => import("../pages/Account/Edit")));
const Partner = Loadable(lazy(() => import("../pages/Partners")));
const Partner_AddEdit = Loadable(lazy(() => import("../pages/Partners/AddEdit")));
const Balance = Loadable(lazy(() => import("../pages/balance")));
const Balance_Add = Loadable(lazy(() => import("../pages/balance/balanceTable/add")));
const LogsReport = Loadable(lazy(() => import("../pages/LogsReport")));
const User = Loadable(lazy(() => import("../pages/Users")));
const User_add = Loadable(lazy(() => import("../pages/Users/add")));
const User_edit = Loadable(lazy(() => import("../pages/Users/edit")));
const AdvertiserCampaign = Loadable(
  lazy(() => import("../pages/Affilation/AdvertiserCampaign/AdvertiserCampaignTable"))
);
const AdvertiserAddCampaign = Loadable(
  lazy(() => import("../pages/Affilation/AdvertiserCampaign/AdvertiserAddCampaign"))
);
const AdvertiserEditCampaign = Loadable(
  lazy(() => import("../pages/Affilation/AdvertiserCampaign/AdvertiserEditCampaign"))
);

const AdvertiserRateCardList = Loadable(lazy(() => import("../pages/Affilation/Advertiser-rate-card")));
const AddAdvertiserRateCard = Loadable(lazy(() => import("../pages/Affilation/Advertiser-rate-card/AddRateCard")));
const EditAdvertiserRateCard = Loadable(lazy(() => import("../pages/Affilation/Advertiser-rate-card/EditRateCard")));

const AdvertiserFactorList = Loadable(lazy(() => import("../pages/Affilation/Advertiser-factor")));
const AddAdvertiserFactor = Loadable(lazy(() => import("../pages/Affilation/Advertiser-factor/AddFactor")));
const EditAdvertiserFactor = Loadable(lazy(() => import("../pages/Affilation/Advertiser-factor/EditFactor")));

const PublisherCampaign = Loadable(lazy(() => import("../pages/Affilation/PublisherCampaign/publisherCampaignTable")));
const PublisherAddCampaign = Loadable(lazy(() => import("../pages/Affilation/PublisherCampaign/PublisherAddCampaign")));
const PublisherEditCampaign = Loadable(
  lazy(() => import("../pages/Affilation/PublisherCampaign/PublisherEditCampaign"))
);

const PublisherRateCardList = Loadable(lazy(() => import("../pages/Affilation/Publisher-rate-card")));
const AddPublisherRateCard = Loadable(lazy(() => import("../pages/Affilation/Publisher-rate-card/AddRateCard")));
const EditPublisherRateCard = Loadable(lazy(() => import("../pages/Affilation/Publisher-rate-card/EditRateCard")));

const PublisherFactorList = Loadable(lazy(() => import("../pages/Affilation/Publisher-factor")));
const AddPublisherFactor = Loadable(lazy(() => import("../pages/Affilation/Publisher-factor/AddFactor")));
const EditPublisherFactor = Loadable(lazy(() => import("../pages/Affilation/Publisher-factor/EditFactor")));

const QueriesReport = Loadable(lazy(() => import("../pages/QueriesReport")));

const NotFound = Loadable(lazy(() => import("../pages/Page404")));

const BlackListedQueries = Loadable(lazy(() => import("../pages/BlackListedQueries")));
const BlackListedQueriesAddPage = Loadable(lazy(() => import("../pages/BlackListedQueries/AddBlacklistedQuery")));
const BlackListedQueriesEditPage = Loadable(lazy(() => import("../pages/BlackListedQueries/EditBlacklistedQuery")));

const RedirectGroups = Loadable(lazy(() => import("../pages/RedirectGroups/index")));
const RedirectGroupsAddEdit = Loadable(lazy(() => import("../pages/RedirectGroups/AddEdit")));
